import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import ProductQuery from '../../types/ProductQuery.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import ProductAvailabilityForm from './Form/ProductAvailabilityForm';
import Results from './Results/Results';
import { cancellableQueryInventory } from './Results/Results.service';
import useProductAvailabilityStore from './useProductAvailabilityStore.store';

interface IProductAvailabilityProp {
  attr?: string;
}

const ProductAvailability: React.FunctionComponent<IProductAvailabilityProp> = () => {
  const { roles } = useRole();
  const productQuery: ProductQuery = useProductAvailabilityStore();
  const [stockInventoryVisible, setStockInventoryVisible] = useState(true);
  const [millInventoryVisible, setMillInventoryVisible] = useState(true);
  const [plannedProductionVisible, setPlannedProductionVisible] = useState(
    true
  );
  const [onlyAvailableItemsVisible, setOnlyAvailableItemsVisible] = useState(
    false
  );
  const [isFilteredStocksSet, setIsFilteredStocksSet] = useState<boolean>(
    false
  );
  const { resolvedData: data, isFetching } = usePaginatedQuery(
    [QueryCacheName.PRODUCT_AVAILABILITY, productQuery],
    cancellableQueryInventory,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: productQuery?.customerSoldTo !== '',
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const isReleaseUser = !!find(roles, {
      authority: AuthorityRole.ROLE_RELEASE,
    });
    setMillInventoryVisible(!isReleaseUser);
    setPlannedProductionVisible(!isReleaseUser);
  }, [roles]);

  return (
    <>
      <ProductAvailabilityForm
        onStockCheckboxChange={setStockInventoryVisible}
        onMillCheckboxChange={setMillInventoryVisible}
        onPlannedProductionCheckboxChange={setPlannedProductionVisible}
        onOnlyAvailableItemsCheckboxChange={setOnlyAvailableItemsVisible}
        setIsFilteredStocksSet={setIsFilteredStocksSet}
        applyProductQuery={(query) =>
          useProductAvailabilityStore.setState({ ...query })
        }
      />
      {isFetching ? (
        <div className="h-80">
          <LoadingSpinner horizontal />
        </div>
      ) : (
        <>
          {productQuery && (
            <Results
              stockInventoryVisible={stockInventoryVisible}
              millInventoryVisible={millInventoryVisible}
              plannedProductionVisible={plannedProductionVisible}
              onlyAvailableItemsVisible={onlyAvailableItemsVisible}
              isFilteredStocksSet={isFilteredStocksSet}
              setIsFilteredStocksSet={setIsFilteredStocksSet}
              data={data || { mill: [], stock: [], productionPlans: [] }}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductAvailability;
