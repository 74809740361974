import { t, Trans } from '@lingui/macro';
import { Space, Tooltip } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import {
  availabilityUpdateRequired,
  backToSearch,
  isAnyRequestedDateBeforeToday,
  showUseLatest,
} from './Basket.util';

interface IUpdateBasketProps {
  basket: AvailabilityBasketEU;
  formMethods: UseFormMethods;
  hasError: boolean;
  reservationBooking: boolean;
  // saveEnquiry: boolean;
  onSubmitBasket: () => void;
  onSaveEnquiry: (params: { reserved: boolean }) => void;
  // onSaveEnquiryAndRequestReservation: (params: { reserved: boolean }) => void;
  onCombineShipment: () => void;
}

const UpdateBasket: FC<IUpdateBasketProps> = ({
  basket,
  formMethods,
  hasError,
  reservationBooking,
  // saveEnquiry,
  onSubmitBasket,
  // onSaveEnquiryAndRequestReservation,
  onSaveEnquiry,
  onCombineShipment,
}) => {
  const {
    formState: { isValid },
    errors,
  } = formMethods;

  // For flags reference to Angular code:
  // - "availabilityUpdateRequired(basket)" is needed to disable BookOrder
  //   button when just switched from UpdateDeliveryDates button.
  // - "updating" flag is not needed since the form is replaced with loading
  //   spinner.
  // - "statusError" is merged to hasError.
  const submitIsDisabled =
    availabilityUpdateRequired(basket) ||
    isAnyRequestedDateBeforeToday(basket, errors) ||
    hasError ||
    !isValid;

  return (
    <>
      <div className="flex justify-between">
        <Button theme="link" onClick={backToSearch} type="button">
          <Trans>Back to Search</Trans>
        </Button>
        <Space size="middle">
          {/* {saveEnquiry && (
            <Button
              theme="primary"
              onClick={() => onSaveEnquiry({ reserved: false })}
              type="button"
            >
              <Trans>Save as Enquiry</Trans>
            </Button>
          )} */}
          {reservationBooking && (
            <Button
              theme="primary"
              onClick={
                () => onSaveEnquiry({ reserved: true })
                // onSaveEnquiryAndRequestReservation({ reserved: true })
              }
              type="button"
            >
              <Trans>Place Reservation</Trans>
            </Button>
          )}
          {showUseLatest(basket) && (
            <Tooltip
              placement="top"
              color="white"
              overlayClassName="sappi-ant-override-tooltip"
              title={t`Changes delivery date of all items to match the item with latest delivery date in order to reduce transport costs.`}
            >
              <Button onClick={onCombineShipment} type="button">
                <Trans>Combine Shipments</Trans>
              </Button>
            </Tooltip>
          )}
          <Button
            theme="primary"
            onClick={onSubmitBasket}
            disabled={submitIsDisabled}
            type="button"
          >
            <Trans>Book Order</Trans>
          </Button>
        </Space>
      </div>
    </>
  );
};

export default UpdateBasket;
