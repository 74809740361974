import { t, Trans } from '@lingui/macro';
import { Modal } from 'antd';
import { every, find, filter as lodashFilter, lowerCase } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import AlertModal from '../../components/AlertModal/AlertModal';
import { Button } from '../../components/Button/Button';
import FilterModal from '../../components/FilterModal/FilterModal';
import QuickOrderTable from '../../components/QuickOrderBookingTable/QuickOrderBookingTable';
import QuickOrderRow from '../../components/QuickOrderBookingTable/QuickOrderRow.interface';
import useQuickOrderFilterStore, {
  DEFAULT_FILTER_STATE,
} from '../../components/QuickOrderTableFilters/filter.store';
import QuickOrderTableFilters from '../../components/QuickOrderTableFilters/QuickOrderTableFilters';
import Select from '../../components/Select/Select';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import useAnalytics from '../../hooks/useAnalytics';
import useBasketMutation from '../../hooks/useBasketMutation';
import useCustomerBasket from '../../hooks/useCustomerBasket';
import useFilterMutation from '../../hooks/useFilterMutation';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useRole from '../../hooks/useRole';
import useUser from '../../hooks/useUser';
import useUserCustomization from '../../hooks/useUserCustomization';
import {
  deriveCustomerOptions,
  getDetailsOfAllEuShipToLocations,
  getDetailsOfAllNaShipTos,
  getEuShipToCustomerOptions,
  getNaShipToOptions,
  getNaShipTos,
} from '../../services/Customer';
import {
  deleteCustomerFilter,
  getCustomerSavedFilter,
} from '../../services/Filter';
import { cancellableStockAvailability } from '../../services/Product';
import { AuthorityRole } from '../../types/Authority.interface';
import { AvailabilityBasketNA } from '../../types/AvailabilityBasketNA.interface';
import { AvailabilityCustomer } from '../../types/AvailabilityCustomer.interface';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import { NaShipTo } from '../../types/NaShipTo.interface';
import Option from '../../types/Option.type';
import Product from '../../types/Product.interface';
import ProductQuery from '../../types/ProductQuery.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';
import QueryCacheName from '../../types/QueryCacheName.enum';
import useSappiBasketStore from '../PageHeader/SappiBasket/sappiBasket.store';
import AddToBasketButton from './AddToBasketButton';
import OtherCustomerInfo from './OtherCustomerInfo';
import styles from './QuickOrderBooking.module.scss';
import SKUSearch from './SKUSearch';
import useQuickOrderBookingStore from './useQuickOrderBooking.store';
import addQuickOrderRowsToNaBasket from './util/addQuickOrderRowsToNaBasket.util';
import { getActualFilterValues } from './util/getActualFilterValues.util';
import isFilterUpdated from './util/isFilterUpdated.util';
import toQuickOrderRows from './util/productsToQuickOrderRows.util';
import { setRequestedWeekdayDefaultDate } from './util/setRequestedWeekdayDefaultDate.util';
// import CustomerInformation from '../../components/CustomerInformation/CustomerInformation';

const DEFAULT_REEL_FILTER = {
  type: PutUpCategory.ROLL,
  brand: '',
  grammage: '',
  caliper: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  rollFilterName: '',
};

const DEFAULT_SHEET_FILTER = {
  type: PutUpCategory.SHEET,
  brand: '',
  grammage: '',
  caliper: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  minLength: undefined,
  maxLength: undefined,
  sheetFilterName: '',
};

const DEFAULT_RELEASE_FILTER = {
  chemistry: '',
  parentBrand: '',
  texture: '',
};

/**
 * TEST CASES:
 * - on customer change filters need to be cleared (because brands may change)
 * - on brand change, grammage and caliper need to be cleared (because there may only be certain values available)
 * - on tab switch, the filters are saved on the previous tab and will be loaded upon return
 * - save filter is only available if local filters match global filters
 * - on SKU search, switches to tab related to SKU result, clears filters for that tab, and does not do an additional search
 */

const { confirm } = Modal;

const QuickOrderBooking: React.FunctionComponent = () => {
  const {
    // selectedCustomerInfo: customerInfo,
    storedQuery: quickOrderBookingQuery,
    setStoredQuery,
  } = useQuickOrderBookingStore();

  const [quickOrderRows, setQuickOrderRows] = useState<QuickOrderRow[]>([]);
  const { trackPageView } = useAnalytics();
  const {
    rollFilter: globalRollFilter,
    setRollFilter: setGlobalRollFilter,
    sheetFilter: globalSheetFilter,
    setSheetFilter: setGlobalSheetFilter,
  } = useQuickOrderFilterStore((state) => state);
  const [reelsFilter, setReelsFilter] = useState<ProductQuery>(
    DEFAULT_REEL_FILTER
  );
  const [sheetsFilter, setSheetsFilter] = useState<ProductQuery>(
    DEFAULT_SHEET_FILTER
  );
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState<
    string
  >();
  const [deliveryLocationDetails, setDeliveryLocationDetails] = useState<
    AvailabilityCustomer[] | NaShipTo[]
  >();
  const [selectedDecisionMaker, setSelectedDecisionMaker] = useState<string>();
  const [deliveryLocationOptions, setDeliveryLocationOptions] = useState<
    Option[]
  >([]);
  const [decisionMakerOptions, setDecisionMakerOptions] = useState<Option[]>(
    []
  );
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<number>();
  const [productOrderNumber, setProductOrderNumber] = useState<string>();
  const { hasRole, isNonReleaseEuUser, isReleaseUser } = useRole();
  const [orderTypeOptions, setOrderTypeOptions] = useState<Option[]>([
    hasRole(AuthorityRole.ROLE_RELEASE)
      ? { value: 'ZOR', label: t`Order General` }
      : { value: 'ZTA', label: t`Order General` },
    { value: 'ZKB', label: t`Replenish Consign` },
  ]);
  const [selectedOrderType, setSelectedOrderType] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<PutUpCategory>(
    quickOrderBookingQuery?.type || PutUpCategory.SHEET
  );
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<ProductQuery>(
    {}
  );
  const [skuSearch, setSkuSearch] = useState<string>('');
  const [reelRowsWithQuantities, setReelRowsWithQuantities] = useState<
    QuickOrderRow[]
  >([]);
  const [sheetRowsWithQuantities, setSheetRowsWithQuantities] = useState<
    QuickOrderRow[]
  >([]);
  const { measurementSystem } = useMeasurementSystem();
  const ignoreUpdate = useRef(false);
  const memoActualFilterValues = useMemo(
    () =>
      getActualFilterValues(
        globalRollFilter,
        globalSheetFilter,
        selectedTab,
        measurementSystem
      ),
    [globalRollFilter, globalSheetFilter, selectedTab, measurementSystem]
  );
  const hasViableRole = () =>
    hasRole(AuthorityRole.ROLE_EU) ||
    hasRole(AuthorityRole.ROLE_NA) ||
    hasRole(AuthorityRole.ROLE_RELEASE);
  const { resolvedData, isFetching } = usePaginatedQuery(
    [QueryCacheName.PRODUCTS, memoActualFilterValues],
    cancellableStockAvailability,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled:
        hasViableRole() && selectedTab === PutUpCategory.ROLL
          ? globalRollFilter.customerSoldTo && globalRollFilter.type
          : globalSheetFilter.customerSoldTo && globalSheetFilter.type,
      keepPreviousData: true,
    }
  );
  const queryCache = useQueryCache();
  const { data: user } = useUser();
  const { getCustomerBasket } = useCustomerBasket();
  const [updateBasket] = useBasketMutation();
  const { loading: basketLoading } = useSappiBasketStore();
  const [visible, setVisible] = useState(false);
  const [filterNumber, setFilterNumber] = useState(0);
  const [rollFilterName, setRollFilterName] = useState('');
  const [sheetFilterName, setSheetFilterName] = useState('');
  const [savedFilters, setSavedFilters] = useState([] as ProductQuery[]);
  const [saveFilter] = useFilterMutation();
  const { data: userCustomization } = useUserCustomization();

  const clearSKU = () => {
    setSkuSearch('');
  };

  const doApplyFilter = useCallback(
    (filter: ProductQuery) => {
      const putUpFilter =
        selectedTab === PutUpCategory.ROLL
          ? {
              ...reelsFilter,
              ...filter,
            }
          : {
              ...sheetsFilter,
              ...filter,
            };
      const contextFilter: Partial<ProductQuery> = {
        customerSoldTo: selectedCustomer,
        type: selectedTab,
      };
      if (selectedTab === PutUpCategory.ROLL) {
        setGlobalRollFilter({
          ...DEFAULT_FILTER_STATE,
          ...contextFilter,
          ...putUpFilter,
        });
      } else {
        setGlobalSheetFilter({
          ...DEFAULT_FILTER_STATE,
          ...contextFilter,
          ...putUpFilter,
        });
      }
    },
    [
      selectedTab,
      reelsFilter,
      sheetsFilter,
      selectedCustomer,
      setGlobalRollFilter,
      setGlobalSheetFilter,
    ]
  );

  // const setSelectedCustomerInfo = useCallback(
  //   (customerNumber: string) => {
  //     useQuickOrderBookingStore.setState({
  //       selectedCustomerInfo: find(user?.euDirectCustomers, {
  //         customerNumber,
  //       }) as AvailabilityCustomer,
  //     });
  //   },
  //   [user]
  // );

  const refreshDeliveryLocationOptionsEU = useCallback(
    (customers: AvailabilityCustomer[] | undefined) => {
      if (selectedCustomer && customers && customers.length > 0) {
        const shipToOptions =
          getEuShipToCustomerOptions(selectedCustomer, customers) || [];
        const firstShipTo = find(shipToOptions, (opt) => !!opt);
        const shipToLocations = getDetailsOfAllEuShipToLocations(
          selectedCustomer,
          customers
        );
        setDeliveryLocationOptions(shipToOptions);
        setSelectedDeliveryLocation(
          firstShipTo ? String(firstShipTo.value) : ''
        );
        setDeliveryLocationDetails(shipToLocations);
      }
    },
    [selectedCustomer]
  );

  const refreshDeliveryLocationOptionsNA = useCallback(() => {
    const getAndUpdateNADeliveryLocations = async () => {
      if (selectedCustomer) {
        const shipTos = await getNaShipTos(selectedCustomer);
        const shipToOptions = getNaShipToOptions(
          selectedCustomer,
          shipTos,
          user?.customers
        );
        const firstShipTo = shipToOptions[0];
        const shipToLocations = getDetailsOfAllNaShipTos(
          selectedCustomer,
          shipTos,
          user?.customers
        );
        setDeliveryLocationOptions(shipToOptions);
        setSelectedDeliveryLocation(
          firstShipTo ? String(firstShipTo.value) : ''
        );
        setDeliveryLocationDetails(shipToLocations);
      }
    };
    getAndUpdateNADeliveryLocations();
  }, [selectedCustomer, user]);

  const refreshSelectedCustomer = useCallback(
    (derivedCustomerOptions: Option[]) => {
      if (!selectedCustomer) {
        const firstNamed = derivedCustomerOptions.find((c) => c.label);
        setSelectedCustomer(
          firstNamed ? (firstNamed.value as string) : undefined
        );
      }
    },
    [selectedCustomer]
  );

  const refreshSavedFilters = useCallback(() => {
    if (selectedCustomer) {
      const getCustomerSavedFilterAsync = async () => {
        const filters = await getCustomerSavedFilter(
          String(selectedCustomer),
          String(selectedTab),
          String(measurementSystem)
        );

        const neededFilters = lodashFilter(
          filters,
          (o) => o.type === selectedTab
        );
        setSavedFilters(neededFilters);
        setFilterNumber(neededFilters ? neededFilters?.length : 0);
      };

      getCustomerSavedFilterAsync();
    }
  }, [measurementSystem, selectedCustomer, selectedTab]);

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const quickOrderCustomers = lodashFilter(customers, { quickOrder: true });
      const derivedCustomerOptions = deriveCustomerOptions(
        quickOrderCustomers || [],
        isNonReleaseEuUser
      );
      setCustomerOptions(derivedCustomerOptions);
      refreshSelectedCustomer(derivedCustomerOptions);
      refreshSavedFilters();
      refreshDeliveryLocationOptionsNA();
    }
  }, [
    user,
    selectedCustomer,
    selectedTab,
    refreshDeliveryLocationOptionsNA,
    refreshSelectedCustomer,
    refreshSavedFilters,
    isNonReleaseEuUser,
  ]);

  // This to assign stored filter criteria when page re-loaded
  useEffect(() => {
    if (quickOrderBookingQuery?.selectedCustomer) {
      setSelectedCustomer(quickOrderBookingQuery?.selectedCustomer);
      setProductOrderNumber(quickOrderBookingQuery?.productOrderNumber);
      if (hasRole(AuthorityRole.ROLE_RELEASE)) {
        setSelectedOrderType('ZOR');
      } else {
        setSelectedOrderType(quickOrderBookingQuery?.selectedOrderType);
      }
      setRequestedDeliveryDate(quickOrderBookingQuery?.requestedDeliveryDate);

      if (quickOrderBookingQuery.type === PutUpCategory.ROLL) {
        setReelsFilter({
          type: PutUpCategory.ROLL,
          brand: quickOrderBookingQuery?.filterForm?.brand,
          finish: quickOrderBookingQuery?.filterForm?.finish,
          grammage: quickOrderBookingQuery?.filterForm?.grammage,
          caliper: quickOrderBookingQuery?.filterForm?.caliper,
          minWidth: quickOrderBookingQuery?.filterForm?.minWidth,
          maxWidth: quickOrderBookingQuery?.filterForm?.maxWidth,
          parentBrand: quickOrderBookingQuery?.filterForm?.parentBrand,
          chemistry: quickOrderBookingQuery?.filterForm?.chemistry,
          texture: quickOrderBookingQuery?.filterForm?.texture,
        });
      } else {
        setSheetsFilter({
          type: PutUpCategory.SHEET,
          brand: quickOrderBookingQuery?.filterForm?.brand,
          grammage: quickOrderBookingQuery?.filterForm?.grammage,
          finish: quickOrderBookingQuery?.filterForm?.finish,
          caliper: quickOrderBookingQuery?.filterForm?.caliper,
          minWidth: quickOrderBookingQuery?.filterForm?.minWidth,
          maxWidth: quickOrderBookingQuery?.filterForm?.maxWidth,
          minLength: quickOrderBookingQuery?.filterForm?.minLength,
          maxLength: quickOrderBookingQuery?.filterForm?.maxLength,
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This to assign stored selectedDeliveryLocation when page re-loaded
  useEffect(() => {
    if (
      quickOrderBookingQuery?.selectedCustomer &&
      selectedCustomer === quickOrderBookingQuery?.selectedCustomer
    ) {
      setSelectedDeliveryLocation(
        selectedDeliveryLocation ||
          quickOrderBookingQuery?.selectedDeliveryLocation ||
          quickOrderBookingQuery?.selectedCustomer
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [deliveryLocationOptions]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!ignoreUpdate.current) {
      clearSKU();
      doApplyFilter({});
    } else {
      ignoreUpdate.current = false;
    }
  }, [doApplyFilter, selectedCustomer, selectedTab]);

  // needed to put SKU result on the right tab
  const setTabBasedOnResult = useCallback(
    (result: Product) => {
      const tab =
        result.productType === 'R' ? PutUpCategory.ROLL : PutUpCategory.SHEET;
      if (selectedTab !== tab) {
        setSelectedTab(tab);
        if (tab === PutUpCategory.ROLL) {
          setReelsFilter(DEFAULT_REEL_FILTER);
        } else {
          setSheetsFilter(DEFAULT_SHEET_FILTER);
        }
        ignoreUpdate.current = true; // so we dont double retrieve results
      }
    },
    [selectedTab]
  );

  const allKCodesTheSame = (products: Product[]) => {
    const firstId = products[0].kcode || products[0].materialNumber;
    return every(
      products,
      (product) =>
        firstId === product.kcode || firstId === product.materialNumber
    );
  };

  function hideFilterName(
    originalFilter: ProductQuery,
    updatedFilter: ProductQuery
  ) {
    if (isFilterUpdated(originalFilter, updatedFilter)) {
      if (selectedTab === PutUpCategory.ROLL) {
        setRollFilterName('');
      } else {
        setSheetFilterName('');
      }
    }
  }

  useEffect(() => {
    setQuickOrderRows(
      toQuickOrderRows(
        resolvedData || [],
        userCustomization?.reelUnit ? userCustomization.reelUnit : 'RL',
        userCustomization?.sheetUnit ? userCustomization.sheetUnit : 'PL',
        measurementSystem === MeasurementSystem.METRIC
      )
    );
    if (
      resolvedData &&
      resolvedData.length > 0 &&
      allKCodesTheSame(resolvedData) &&
      skuSearch
    ) {
      setTabBasedOnResult(resolvedData[0]);
    }
  }, [
    resolvedData,
    measurementSystem,
    skuSearch,
    setTabBasedOnResult,
    userCustomization,
  ]);

  useEffect(() => {
    if (isReleaseUser) {
      setSelectedTab(PutUpCategory.ROLL);
    }
  }, [isReleaseUser]);

  function onTabChange(key: string) {
    if (key === PutUpCategory.ROLL) {
      trackPageView('QUICK_ORDER_BOOKING', 'OPEN_ROLL_TAB', {
        customerId: selectedCustomer,
      });
    } else {
      trackPageView('QUICK_ORDER_BOOKING', 'OPEN_SHEET_TAB', {
        customerId: selectedCustomer,
      });
    }
    clearSKU();
    setSelectedTab(
      key === PutUpCategory.ROLL ? PutUpCategory.ROLL : PutUpCategory.SHEET
    );
  }

  function onSKUSearch(term: string) {
    trackPageView('QUICK_ORDER_BOOKING', 'SKU_LOOKUP', {
      customerId: selectedCustomer,
    });
    const appliedFilter =
      selectedTab === PutUpCategory.ROLL
        ? {
            ...globalRollFilter,
            skuCodes: [term],
          }
        : {
            ...globalSheetFilter,
            skuCodes: [term],
          };

    queryCache.invalidateQueries([QueryCacheName.PRODUCTS, appliedFilter]);

    doApplyFilter({
      skuCodes: [term],
    });
    setSkuSearch(term);
  }

  function doSaveAndApplyFilter(filter: ProductQuery) {
    setSkuSearch('');
    if (selectedTab === PutUpCategory.ROLL) {
      trackPageView('QUICK_ORDER_BOOKING', 'APPLY_REEL_FILTER', {
        customerId: selectedCustomer,
      });
      setReelsFilter({
        ...filter,
        skuCodes: undefined,
        type: PutUpCategory.ROLL,
      });
    } else {
      trackPageView('QUICK_ORDER_BOOKING', 'APPLY_SHEET_FILTER', {
        customerId: selectedCustomer,
      });
      setSheetsFilter({
        ...filter,
        skuCodes: undefined,
        type: PutUpCategory.SHEET,
      });
    }
    doApplyFilter(filter);

    setStoredQuery({
      ...quickOrderBookingQuery,
      selectedCustomer,
      type:
        selectedTab === PutUpCategory.ROLL
          ? PutUpCategory.ROLL
          : PutUpCategory.SHEET,
      filterForm: {
        ...filter,
      },
    });
  }

  function doFilterReset() {
    trackPageView('QUICK_ORDER_BOOKING', 'RESET_FORM');
    if (!hasRole(AuthorityRole.ROLE_RELEASE)) {
      if (selectedTab === PutUpCategory.ROLL) {
        doSaveAndApplyFilter(DEFAULT_REEL_FILTER);
        setRollFilterName('');
      } else {
        doSaveAndApplyFilter(DEFAULT_SHEET_FILTER);
        setSheetFilterName('');
      }
    } else {
      doSaveAndApplyFilter(DEFAULT_RELEASE_FILTER);
      setRollFilterName('');
    }
  }

  function doSetSelectedCustomer(customer: string) {
    trackPageView('QUICK_ORDER_BOOKING', 'SELECT_CUSTOMER', {
      customerId: customer,
    });
    setReelsFilter(DEFAULT_REEL_FILTER);
    setSheetsFilter(DEFAULT_SHEET_FILTER);
    setSelectedCustomer(customer);

    setSelectedDeliveryLocation('');
    if (hasRole(AuthorityRole.ROLE_RELEASE)) {
      setSelectedOrderType('ZOR');
    } else {
      setSelectedOrderType('ZTA');
    }
    setProductOrderNumber('');
    setRollFilterName('');
    setSheetFilterName('');
    // setSelectedCustomerInfo(customer);
  }

  function setFilterName(chosenFilter: ProductQuery) {
    if (selectedTab === PutUpCategory.ROLL) {
      setRollFilterName(String(chosenFilter?.filterName));
    } else {
      setSheetFilterName(String(chosenFilter?.filterName));
    }
  }

  function onReelTableUpdate(data: QuickOrderRow[]) {
    const rowsWithQuantities = data.filter((row) =>
      row.userEnteredQuantity ? row.userEnteredQuantity > 0 : false
    );
    setReelRowsWithQuantities(rowsWithQuantities);
  }
  function onSheetTableUpdate(data: QuickOrderRow[]) {
    const rowsWithQuantities = data.filter((row) =>
      row.userEnteredQuantity ? row.userEnteredQuantity > 0 : false
    );
    setSheetRowsWithQuantities(rowsWithQuantities);
  }

  const addQuantifiedProductsToNaBasket = async (
    rowsToAdd: QuickOrderRow[]
  ) => {
    const customerBasket = getCustomerBasket(selectedCustomer || '');
    const selectedDeliveryLocationDetails = find<
      AvailabilityCustomer | NaShipTo
    >(
      deliveryLocationDetails,
      // PHASE 2
      // isNonReleaseEuUser
      //   ? { shipToCustomerNumber: selectedDeliveryLocation }
      //   : { customerNumber: selectedDeliveryLocation }
      { customerNumber: selectedDeliveryLocation }
    );
    if (customerBasket) {
      const updatedBasket = addQuickOrderRowsToNaBasket(
        rowsToAdd,
        getCustomerBasket(selectedCustomer || ''),
        requestedDeliveryDate === undefined
          ? setRequestedWeekdayDefaultDate(
              hasRole(AuthorityRole.ROLE_EU)
            ).valueOf()
          : requestedDeliveryDate,
        productOrderNumber,
        selectedOrderType,
        selectedDeliveryLocationDetails
      );
      trackPageView('QUICK_ORDER_BOOKING', 'ADD_TO_BASKET', {
        customerId: selectedCustomer,
      });
      return updateBasket(updatedBasket as AvailabilityBasketNA);
    }
    return Promise.resolve;
  };

  // TODO: PHASE 2
  // const addQuantifiedProductsToEuBasket = async (
  //   rowsToAdd: QuickOrderRow[]
  // ) => {
  //   const productsToAdd = toStockAvailabilityResults(
  //     rowsToAdd,
  //     selectedCustomer || '',
  //     selectedDeliveryLocation
  //   );
  //   return Promise.all(
  //     productsToAdd.map((product) => addEuStockToBasket(product))
  //   );
  // };

  /**
   * Adds items with quantities to the basket
   */
  const addQuantifiedProductsToBasket = async () => {
    const rowsToAdd =
      selectedTab === PutUpCategory.ROLL
        ? reelRowsWithQuantities
        : sheetRowsWithQuantities;
    // TODO: PHASE 2
    // if (
    //   hasRole(AuthorityRole.ROLE_EU) &&
    //   !hasRole(AuthorityRole.ROLE_RELEASE)
    // ) {
    //   await addQuantifiedProductsToEuBasket(rowsToAdd);
    // } else {
    //   await addQuantifiedProductsToNaBasket(rowsToAdd);
    // }
    setQuickOrderRows([...quickOrderRows]);
    setReelRowsWithQuantities([]);
    setSheetRowsWithQuantities([]);

    await addQuantifiedProductsToNaBasket(rowsToAdd);
  };

  /**
   * Checks if there are any items not added to basket yet before proceeding
   */
  function unAddedItemsCheckBeforeAction(okAction: () => unknown) {
    const showModal =
      selectedTab === PutUpCategory.ROLL
        ? reelRowsWithQuantities.length > 0
        : sheetRowsWithQuantities.length > 0;

    if (showModal) {
      const { destroy } = confirm({
        maskTransitionName: 'BOOTSTRAP_FIX',
        modalRender() {
          return (
            <AlertModal
              size="md"
              title="Un-Added Items"
              body="Do you want to add the items currently selected to your Order Basket
            prior to modifying the search results?"
              buttons={[
                <Button key="cancel" onClick={destroy}>
                  No, continue to modify my search results
                </Button>,
                <Button
                  key="ok"
                  theme="primary"
                  onClick={() => {
                    addQuantifiedProductsToBasket();
                    okAction();
                    destroy();
                  }}
                >
                  Yes, add items to my Order Basket
                </Button>,
              ]}
            />
          );
        },
      });
    } else {
      okAction();
    }
  }

  const SavedFiltersComponent = {
    right: (
      <div className="flex items-center">
        {(selectedTab === PutUpCategory.ROLL
          ? rollFilterName
          : sheetFilterName) && (
          <Button
            theme="tiny"
            width="200"
            onClick={() =>
              unAddedItemsCheckBeforeAction(() => {
                doFilterReset();
              })
            }
          >
            Filter:
            <span className="ml-1" />
            {selectedTab === PutUpCategory.ROLL
              ? rollFilterName
              : sheetFilterName}
            <span className="ml-4 fas fa-times text-lg" />
          </Button>
        )}
        <span className="mx-2" />
        <Button
          theme="primary"
          type="submit"
          disabled={filterNumber < 1}
          onClick={() => setVisible(true)}
        >
          Saved Filters ({filterNumber})
        </Button>
      </div>
    ),
  };

  const QuickOrderTableRollFilter = (
    <QuickOrderTableFilters
      filter={reelsFilter}
      type={PutUpCategory.ROLL}
      customer={selectedCustomer}
      context="RL"
      tableRowHasQuantityEntered={reelRowsWithQuantities.length > 0}
      onApply={(filter) =>
        unAddedItemsCheckBeforeAction(() => {
          hideFilterName(selectedSavedFilter, filter);
          doSaveAndApplyFilter(filter);
        })
      }
      onReset={() => {
        unAddedItemsCheckBeforeAction(() => {
          doFilterReset();
        });
      }}
      onSave={(filter) => {
        saveFilter({
          ...filter,
          measurementSystem,
          type: PutUpCategory.ROLL,
          customerSoldTo: selectedCustomer,
        }).then((savedFilter) => {
          if (savedFilter) {
            if (
              savedFilters.findIndex(
                (f) =>
                  lowerCase(f.filterName) === lowerCase(savedFilter.filterName)
              ) < 0
            ) {
              setFilterNumber(savedFilters.push(savedFilter));
              setSavedFilters(savedFilters);
            }

            setRollFilterName(String(savedFilter.filterName));
            setVisible(false);
          }
        });
      }}
    />
  );

  const NonReleaseUserExperience = (
    <Tabs
      type="card"
      activeKey={selectedTab}
      onChange={(key) => {
        const tab = key;
        unAddedItemsCheckBeforeAction(() => onTabChange(tab));
      }}
      tabBarExtraContent={SavedFiltersComponent}
    >
      <TabPane
        tab={t`Rolls`}
        key={PutUpCategory.ROLL}
        className="p-4 -mt-5 border-l border-r border-b border-gray-light200"
      >
        {QuickOrderTableRollFilter}
        <div className="flex justify-between my-3">
          <SKUSearch
            searchString={skuSearch}
            onSubmit={(data) =>
              unAddedItemsCheckBeforeAction(() => onSKUSearch(data))
            }
          />
          <AddToBasketButton
            theme="primary"
            loading={basketLoading}
            onClick={addQuantifiedProductsToBasket}
            disabled={reelRowsWithQuantities.length === 0}
          />
        </div>
        <QuickOrderTable
          onCellEdit={onReelTableUpdate}
          putUpCategory={PutUpCategory.ROLL}
          loading={isFetching}
          data={quickOrderRows}
        />
      </TabPane>
      <TabPane
        tab={t`Sheets`}
        key={PutUpCategory.SHEET}
        className="p-4 -mt-5 border-l border-r border-b border-gray-light200"
      >
        <QuickOrderTableFilters
          filter={sheetsFilter}
          type={PutUpCategory.SHEET}
          customer={selectedCustomer}
          context="PL"
          tableRowHasQuantityEntered={sheetRowsWithQuantities.length > 0}
          onApply={(filter) =>
            unAddedItemsCheckBeforeAction(() => {
              hideFilterName(selectedSavedFilter, filter);
              doSaveAndApplyFilter(filter);
            })
          }
          onReset={() => {
            unAddedItemsCheckBeforeAction(() => {
              doFilterReset();
            });
          }}
          onSave={(filter) => {
            saveFilter({
              ...filter,
              measurementSystem,
              type: PutUpCategory.SHEET,
              customerSoldTo: selectedCustomer,
            }).then((savedFilter) => {
              if (savedFilter) {
                if (
                  savedFilters.findIndex(
                    (f) =>
                      lowerCase(f.filterName) ===
                      lowerCase(savedFilter.filterName)
                  ) < 0
                ) {
                  setFilterNumber(savedFilters.push(savedFilter));
                  setSavedFilters(savedFilters);
                }

                setSheetFilterName(String(savedFilter.filterName));
                setVisible(false);
              }
            });
          }}
        />
        <div className="flex justify-between my-3">
          <SKUSearch
            searchString={skuSearch}
            onSubmit={(data) =>
              unAddedItemsCheckBeforeAction(() => onSKUSearch(data))
            }
          />
          <AddToBasketButton
            theme="primary"
            loading={basketLoading}
            onClick={addQuantifiedProductsToBasket}
            disabled={sheetRowsWithQuantities.length === 0}
          />
        </div>
        <QuickOrderTable
          onCellEdit={onSheetTableUpdate}
          putUpCategory={PutUpCategory.SHEET}
          loading={isFetching}
          data={quickOrderRows}
        />
      </TabPane>
    </Tabs>
  );

  const ReleaseUserExperience = (
    <>
      <div className="my-3 flex justify-end">
        <div className="flex items-center">
          {rollFilterName && (
            <Button
              theme="tiny"
              width="200"
              onClick={() =>
                unAddedItemsCheckBeforeAction(() => {
                  doFilterReset();
                })
              }
            >
              Filter:
              <span className="ml-1" />
              {rollFilterName}
              <span className="ml-4 fas fa-times text-lg" />
            </Button>
          )}
          <span className="mx-2" />
          <Button
            theme="primary"
            type="submit"
            disabled={filterNumber < 1}
            onClick={() => setVisible(true)}
          >
            Saved Filters ({filterNumber})
          </Button>
        </div>
      </div>
      {QuickOrderTableRollFilter}
      <div className="flex justify-between my-3">
        <div />
        <AddToBasketButton
          theme="primary"
          loading={basketLoading}
          onClick={addQuantifiedProductsToBasket}
          disabled={reelRowsWithQuantities.length === 0}
        />
      </div>
      <QuickOrderTable
        onCellEdit={onReelTableUpdate}
        putUpCategory={PutUpCategory.ROLL}
        loading={isFetching}
        data={quickOrderRows}
      />
    </>
  );

  return (
    <div className={styles['quick-order-container']}>
      <div className="relative">
        <div className="flex items-center">
          <div className={styles['customer-select-label']}>
            <Trans>Customer:</Trans>
          </div>
          <div className={customerOptions.length === 1 ? 'text-2xl' : ''}>
            <Select
              className={styles['customer-select']}
              value={selectedCustomer}
              options={customerOptions}
              omitBoxOutline
              hideIfOneOption
              onChange={(e) => {
                const customer = e.target.value;
                unAddedItemsCheckBeforeAction(() =>
                  doSetSelectedCustomer(customer)
                );
                setStoredQuery({
                  selectedCustomer: customer,
                });
              }}
            />
          </div>
        </div>
        {/* <div className="mt-5">
          {customerInfo && (
            <CustomerInformation selectedCustomer={customerInfo} />
          )}
        </div> */}
        <div className="my-5">
          <OtherCustomerInfo
            deliveryLocationOptions={deliveryLocationOptions}
            decisionMakerOptions={decisionMakerOptions}
            orderTypeOptions={orderTypeOptions}
            selectedDeliveryLocation={selectedDeliveryLocation}
            selectedDecisionMaker={selectedDecisionMaker}
            selectedOrderType={selectedOrderType}
            productOrderNumber={productOrderNumber}
            requestedDeliveryDate={requestedDeliveryDate}
            onDeliveryLocationChange={(location) => {
              setSelectedDeliveryLocation(location);
              setStoredQuery({
                ...quickOrderBookingQuery,
                selectedCustomer,
                selectedDeliveryLocation: location,
              });
            }}
            onDecisionMakerChange={setSelectedDecisionMaker}
            onOrderTypeChange={(orderType) => {
              setSelectedOrderType(orderType);
              setStoredQuery({
                ...quickOrderBookingQuery,
                selectedCustomer,
                selectedOrderType: orderType,
              });
            }}
            onRequestedDateChange={(dateInMs) => {
              setRequestedDeliveryDate(dateInMs);
              setStoredQuery({
                ...quickOrderBookingQuery,
                selectedCustomer,
                requestedDeliveryDate: dateInMs,
              });
            }}
            onProductOrderNumberChange={(orderNumber) => {
              setProductOrderNumber(orderNumber);
              setStoredQuery({
                ...quickOrderBookingQuery,
                selectedCustomer,
                productOrderNumber: orderNumber,
              });
            }}
          />
        </div>
      </div>
      {hasRole(AuthorityRole.ROLE_RELEASE)
        ? ReleaseUserExperience
        : NonReleaseUserExperience}

      <FilterModal
        visible={visible}
        showSavedFilters={true as boolean}
        selectedCustomer={selectedCustomer}
        rollFilterName={rollFilterName}
        sheetFilterName={sheetFilterName}
        type={selectedTab}
        onCancel={() => setVisible(false)}
        applyFilter={(chosenFilter?: ProductQuery) => {
          if (chosenFilter) {
            unAddedItemsCheckBeforeAction(() => {
              setFilterName(chosenFilter);
              setSelectedSavedFilter(chosenFilter);

              doSaveAndApplyFilter(chosenFilter);
            });
          }
          setVisible(false);
        }}
        deleteFilter={(chosenFilter?: ProductQuery) => {
          if (chosenFilter) {
            deleteCustomerFilter(
              String(selectedCustomer),
              String(chosenFilter?.filterName),
              String(selectedTab),
              String(measurementSystem)
            ).then(() => {
              const updatedFilters = lodashFilter(
                savedFilters,
                (o) => o.filterName !== String(chosenFilter?.filterName)
              );
              setSavedFilters(updatedFilters);
              setFilterNumber(updatedFilters.length);
              setVisible(false);
            });
          }
        }}
      />
    </div>
  );
};

export default QuickOrderBooking;
