import { t, Trans } from '@lingui/macro';
import { find, map, maxBy } from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import CustomerInformation from '../../../components/CustomerInformation/CustomerInformation';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import useEUAvailabilityCustomer from '../../../hooks/useEUAvailabilityCustomer';
import {
  saveEuBasket,
  submitEuOrderBookingBasketOrder,
  updateEuBasket,
} from '../../../services/Basket';
import {
  euNumStringFormat,
  parseNumberString,
} from '../../../services/Util/parseNumberString.util';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { availabilityUpdateRequired } from './Basket.util';
import BasketInformation from './BasketInformation/BasketInformation';
import BasketTable from './BasketTable';
import { BasketFormData } from './types/BasketFormData.interface';
import useBasketStatusUpdate from './useBasketStatusUpdate';
import useBasketStore from './useBasketStore.store';

interface IEuBasketFormProps {
  loadedBasket: AvailabilityBasketEU;
  refetchBaskets: () => void;
}

const EuBasketForm: FC<IEuBasketFormProps> = ({
  loadedBasket,
  refetchBaskets,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasError, setHasError] = useState(false);
  const queryCache = useQueryCache();
  const { trackPageView } = useAnalytics();
  const history = useHistory();

  const { statusUpdating, statusError } = useBasketStatusUpdate(
    loadedBasket,
    refetchBaskets
  );
  const { getAvailabilityCustomerByNumber } = useEUAvailabilityCustomer();
  const selectedCustomer = getAvailabilityCustomerByNumber(
    loadedBasket.soldTo.number
  );

  const { baskets } = useBasketStore();
  const basket = find(baskets, { id: loadedBasket.id });

  const formMethods = useForm({
    mode: 'all',
  });
  const {
    formState: { isValid },
    register,
    errors,
    trigger,
    watch,
    getValues,
    handleSubmit,
  } = formMethods;

  // Refer to saveEnquiryAndReserve in AvailabilityBasketCtrl.js
  const saveEnquiryAndRequestReservation = async ({
    reserved = true,
  }: {
    reserved?: boolean;
  } = {}) => {
    if (basket) {
      trackPageView('BASKET', 'SAVE_ENQUIRY_RESERVE', {
        customerId: loadedBasket.soldTo.number,
      });

      setHasError(false);
      try {
        setIsUpdating(true);
        await saveEuBasket({
          ...basket,
          reserved,
          purchaseOrder: getValues(`purchaseOrder`) || '',
        });
      } catch (e) {
        setHasError(true);
      } finally {
        setIsUpdating(false);

        history.push(`/basket/${basket.id}/saved`);

        // The basket status service is called when basket date is reloaded in EuBasket.tsx
        queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
      }
    }
  };

  // const saveEnquiry = async () => {
  //   if (basket) {
  //     trackPageView('BASKET', 'SAVE_ENQUIRY', {
  //       customerId: loadedBasket.soldTo.number,
  //     });

  //     setHasError(false);
  //     try {
  //       setIsUpdating(true);
  //       await saveEuBasket({
  //         ...basket,
  //         reserved: false,
  //         forOrderBooking: false,
  //         saved: true,
  //         purchaseOrder: getValues(`purchaseOrder`) || '',
  //       });
  //     } catch (e) {
  //       setHasError(true);
  //     } finally {
  //       setIsUpdating(false);

  //       history.push(`/basket/${basket.id}/saved`);

  //       // The basket status service is called when basket date is reloaded in EuBasket.tsx
  //       queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
  //     }
  //   }
  // };

  const updateBasket = async (data: BasketFormData) => {
    if (basket) {
      trackPageView('BASKET', 'UPDATE_BASKET', {
        customerId: loadedBasket.soldTo.number,
      });

      setHasError(false);
      try {
        setIsUpdating(true);
        await updateEuBasket({
          ...basket,
          purchaseOrder: data.purchaseOrder || null,
          items: map(basket.items, (item, index) => ({
            ...item,
            newLabel: data.basket[index].newLabel,
            paperType: data.basket[index].paperType,
            requestedDate: moment(data.basket[index].requestedDate).valueOf(),
            requestedQuantity: parseNumberString(
              data.basket[index].requestedQuantity.toString(),
              euNumStringFormat
            ),
          })),
        });
      } catch (e) {
        setHasError(true);
      } finally {
        setIsUpdating(false);

        // The basket status service is called when basket date is reloaded in EuBasket.tsx
        queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
      }
    }
  };

  const combineShipment = () => {
    if (basket && isValid) {
      trackPageView('BASKET', 'COMBINE_SHIPMENT', {
        customerId: loadedBasket.soldTo.number,
      });
      setHasError(false);

      const updateEuBasketAsync = async () => {
        const maxDate = maxBy(basket.items, 'confirmedDate')?.confirmedDate;
        if (maxDate) {
          try {
            setIsUpdating(true);
            await updateEuBasket({
              ...basket,
              purchaseOrder: watch('purchaseOrder'),
              items: map(basket.items, (item) => ({
                ...item,
                requestedDate: maxDate,
                requiresUpdate: item.requestedDate !== maxDate,
              })),
            });
          } catch (e) {
            setHasError(true);
          } finally {
            setIsUpdating(false);
            queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
          }
        }
      };

      updateEuBasketAsync();
    }
  };

  const submitBasket = async (values: BasketFormData): Promise<void> => {
    if (basket) {
      setIsUpdating(true);

      trackPageView('ORDER_BOOKING', 'BOOK_ORDER');

      const basketToSubmit: AvailabilityBasketEU = {
        ...basket,
        purchaseOrder: values.purchaseOrder || null,
      };

      await submitEuOrderBookingBasketOrder(basketToSubmit);

      queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
      history.push(`/basket/${basket.id}/booked`);

      setIsUpdating(false);
    }
  };

  return (
    <>
      {statusUpdating || isUpdating ? (
        <>
          <LoadingSpinner horizontal />
          <div className="text-center my-16">
            <Trans>Updating the basket. Please wait.</Trans>
          </div>
        </>
      ) : (
        <>
          {basket && (
            <>
              {statusError && (
                <div>
                  <Trans>There was a problem updating your basket.</Trans>
                </div>
              )}
              <BasketInformation
                basket={basket}
                key={`basketInformation${basket.id}`}
              />
              <form
                className="py-5"
                key={`form${basket.id}`}
                onSubmit={() => false}
              >
                <InputLabel text={t`Purchase Order`} size="lg" required>
                  <Input
                    required
                    style={{ width: '160px' }}
                    name="purchaseOrder"
                    defaultValue={basket.purchaseOrder || ''}
                    ref={register({
                      required: !availabilityUpdateRequired(basket),
                      validate: {
                        required: (value) =>
                          !!value.trim() || availabilityUpdateRequired(basket),
                      },
                    })}
                    onChange={() => trigger()}
                    error={errors?.purchaseOrder}
                  />
                </InputLabel>
                <CustomerInformation
                  selectedCustomer={selectedCustomer}
                  isInBasket
                  deliveryLocationName={basket.shipTo?.name}
                />
                <BasketTable
                  basket={basket}
                  formMethods={formMethods}
                  hasError={hasError || statusError}
                  reservationBooking={
                    !!loadedBasket?.soldTo?.reservationBooking
                  }
                  onUpdateBasket={handleSubmit(updateBasket)}
                  onSubmitBasket={handleSubmit(submitBasket)}
                  onSaveEnquiry={saveEnquiryAndRequestReservation}
                  // onSaveEnquiryAndRequestReservation={
                  //   saveEnquiryAndRequestReservation
                  // }
                  // saveEnquiry={!!loadedBasket?.soldTo?.reservationBooking}
                  // onSaveEnquiry={saveEnquiry}
                  onCombineShipment={combineShipment}
                />
              </form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EuBasketForm;
