import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useAnalytics from '../../../hooks/useAnalytics';
import { getSamplesParameters } from '../../../services/SamplesOrder';
import Option from '../../../types/Option.type';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import useUserBasedSampleOptions from '../hooks/useUserBasedSampleOptions';
import SamplesProductGroup from './SamplesProductGroup.enum';
import { SamplesSearchFilters } from './SamplesSearchFilters.interface';
import { getOptions } from './SearchForm.util';

const INPUT_WIDTH = '30%';
interface ISearchFormProps {
  onSearch: (filters: Partial<SamplesSearchFilters>) => void;
}

const SearchForm: React.FunctionComponent<ISearchFormProps> = ({
  onSearch,
}) => {
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const { register, handleSubmit, reset, errors, watch, getValues } = useForm<
    SamplesSearchFilters
  >({
    mode: 'all',
  });

  const form = watch();

  const {
    isFetching: isFetchingSampleTypeOptions,
    sampleTypeOptions,
    getUserBasedTypeParameters,
  } = useUserBasedSampleOptions();
  const [brandFamilyOptions, setBrandFamilyOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [sizeOptions, setSizeOptions] = useState<Option[]>([]);
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [surfaceOptions, setSurfaceOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Partial<SamplesSearchFilters>>({
    productGroup: SamplesProductGroup.NONE,
  });

  const {
    resolvedData: parameters,
    isFetching: isFetchingParameters,
  } = usePaginatedQuery(
    [QueryCacheName.SAMPLES_PARAMETERS, filters],
    getSamplesParameters,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !isFetchingSampleTypeOptions,
    }
  );

  const updateFilters = () => {
    setFilters(omit({ ...getValues() }, ['materialSearchInput']));
  };

  const onSubmit = handleSubmit((newFilter) => {
    console.log(`Track Page View SAMPLES SEARCH`);
    trackPageView('SAMPLES', 'SEARCH');
    onSearch(newFilter);
  });

  const onReset = () => {
    const groupName = getValues('productGroup');
    reset({
      productGroup: groupName,
    });
    setFilters({
      productGroup: groupName,
    });
  };

  useEffect(() => {
    setBrandFamilyOptions(
      getOptions(parameters?.brandFamilies, 'display', 'brandFamily')
    );
    setProductOptions(getOptions(parameters?.brands, 'brandName', 'brand'));
    setSizeOptions(getOptions(parameters?.sizes, 'display', 'size'));
    setTypeOptions(
      getOptions(
        getUserBasedTypeParameters(parameters?.types),
        'display',
        'sampleType'
      )
    );
    setSurfaceOptions(getOptions(parameters?.surfaces, 'display', 'surface'));
    setGrammageOptions(
      getOptions(parameters?.grammages, 'grammage', 'grammage')
    );
  }, [parameters, getUserBasedTypeParameters]);

  return (
    <div className="border-t-4 border-blue-cobaltVariation mb-5">
      <form
        onSubmit={onSubmit}
        className="bg-white-offWhite border  border-gray-light400 p-6"
      >
        <div className="mb-4">
          <InputLabel
            size="lg"
            text={t`Sample Type`}
            style={{ width: INPUT_WIDTH }}
          >
            <Select
              name="productGroup"
              ref={register()}
              width="full"
              options={sampleTypeOptions}
              onChange={(e) => {
                if (e.target.value === SamplesProductGroup.DUMMY) {
                  history.push('/samples/createDummy');
                } else {
                  onReset();
                }
              }}
            />
          </InputLabel>
        </div>
        <div className="flex justify-between mb-4">
          <InputLabel
            size="lg"
            text={t`Brand`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="brandFamily"
              ref={register()}
              width="full"
              options={brandFamilyOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Product`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="brand"
              ref={register()}
              width="full"
              options={productOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Size`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="size"
              ref={register()}
              width="full"
              options={sizeOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
        </div>
        <div className="flex justify-between mb-4">
          <InputLabel
            size="lg"
            text={t`Type`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="type"
              ref={register()}
              width="full"
              options={typeOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Surface`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="surface"
              ref={register()}
              width="full"
              options={surfaceOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Grammage`}
            style={{ width: INPUT_WIDTH }}
            isFetching={isFetchingParameters || isFetchingSampleTypeOptions}
          >
            <Select
              name="grammage"
              ref={register()}
              width="full"
              options={grammageOptions}
              disabled={isFetchingParameters || isFetchingSampleTypeOptions}
              onChange={() => updateFilters()}
            />
          </InputLabel>
        </div>
        <div className="flex justify-between items-end mb-4">
          <InputLabel
            size="lg"
            text={t`Material ID`}
            style={{ width: INPUT_WIDTH }}
          >
            <Input
              name="materialSearchInput"
              ref={register({
                maxLength: 40,
              })}
              width="full"
              error={errors.materialSearchInput}
            />
          </InputLabel>
          <Space size="middle">
            <Button
              type="submit"
              theme="primary"
              disabled={
                (!form.brand &&
                  !form.brandFamily &&
                  !form.grammage &&
                  !form.materialSearchInput &&
                  !form.size &&
                  !form.surface &&
                  !form.type) ||
                !!errors.materialSearchInput
              }
            >
              <Trans>Search</Trans>
            </Button>
            <Button
              type="reset"
              theme="link"
              className="text-lg font-bold"
              onClick={onReset}
            >
              <Trans>Reset</Trans>
            </Button>
          </Space>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
