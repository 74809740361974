import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ControlledDatePicker from '../../../../components/DatePicker/ControlledDatePicker';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormField from './types/CustomerBasketFormField.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';

const MAX_LENGTH = 18;

interface ItemInfoCellContentProps {
  basketItem: AvailabilityBasketItem;
  formMethods: UseFormMethods<CustomerBasketFormData>;
  itemIndex: number;
  onRemoveItem?: () => void;
}

const ItemInfoCellContent: FC<ItemInfoCellContentProps> = ({
  basketItem,
  formMethods,
  itemIndex,
  onRemoveItem,
}) => {
  const { control, errors, register, setValue } = formMethods;
  const custItemNumFieldName = `items.${itemIndex}.${CustomerBasketFormField.CUSTOMER_MATERIAL}`;
  const lastRevisionDate = `items.${itemIndex}.${CustomerBasketFormField.LAST_DATE_OF_REVISION}`;

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onRemoveItem) {
      onRemoveItem();
    }
  };

  return (
    <div className="flex justify-between items-center mr-4">
      <div className="flex-auto">
        <Space align="start" size="middle" className="w-full mb-3">
          <InputLabel text={t`Requested Delivery`} size="lg">
            <ControlledDatePicker
              name={`items.${itemIndex}.${CustomerBasketFormField.REQUESTED_DATE}`}
              control={control}
              rules={{
                required: true,
                validate: {
                  isDateAfterToday: (date: Moment) => date.isAfter(),
                },
              }}
              hasError={
                !!errors?.items?.[itemIndex]?.[
                  CustomerBasketFormField.REQUESTED_DATE
                ]
              }
              required
            />
          </InputLabel>
          <InputLabel text={t`Customer Item #`} size="lg">
            <Input
              name={custItemNumFieldName}
              ref={register({ maxLength: MAX_LENGTH })}
              onChange={(event) => {
                if (event.target.value.length > MAX_LENGTH) {
                  setValue(
                    custItemNumFieldName,
                    event.target.value.substring(0, MAX_LENGTH)
                  );
                }
              }}
            />
          </InputLabel>
        </Space>
        <div>
          <Checkbox
            label={t`Last Date of Revision`}
            ref={register()}
            name={lastRevisionDate}
            defaultChecked={basketItem.ldr === true}
          />
        </div>
      </div>
      <div className="flex-none">
        <button onClick={handleRemoveClick}>
          <span className="sr-only">
            <Trans>Remove item</Trans>
          </span>
          <i className="fa fa-trash fa-2x" />
        </button>
      </div>
    </div>
  );
};

export default ItemInfoCellContent;
