import React, { FC } from 'react';

interface EqCalculatorResultsSectionProps {
  title: string;
}

const ResultsSection: FC<EqCalculatorResultsSectionProps> = ({
  title,
  children,
}) => {
  return (
    <div>
      <h3 className="px-4 py-0.5 mb-4 text-lg font-bold text-white-white bg-blue-cobaltVariation">
        {title}
      </h3>
      <div>{children}</div>
    </div>
  );
};

export default ResultsSection;
