import * as React from 'react';
import { t } from '@lingui/macro';
import { UseFormMethods } from 'react-hook-form';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import useUser from '../../hooks/useUser';
import Region from '../../types/Region.enum';
import InputLabel from '../../components/InputLabel';
import Input from '../../components/Input/Input';
import { METRIC_NUMBER_THREE_REG_EX } from '../../types/RegularExpression.constants';
import { getTotalClaimValueFromString } from './FileClaimConfirmation.util';

interface IFileClaimFormClaimValuesProps {
  formMethods: UseFormMethods;
  chosenClaimType: ClaimTypes;
}
const FileClaimFormClaimValues: React.FunctionComponent<IFileClaimFormClaimValuesProps> = ({
  formMethods,
  chosenClaimType,
}) => {
  const { data: user } = useUser();
  const isEuUser = user?.region === Region.EU;
  const { register, errors, watch } = formMethods;
  const claimValue = watch('claimValue');
  const additionalClaimValue = watch('additionalClaimValue');

  return (
    <>
      {((isEuUser && chosenClaimType === ClaimTypes.TRANSIT) ||
        chosenClaimType === ClaimTypes.QUALITY) && (
        <>
          <InputLabel
            size="lg"
            required
            text={t`Paper Claim Value`}
            className="py-1 pt-5"
            style={{ width: '500px' }}
          >
            <Input
              name="claimValue"
              width="full"
              ref={register({
                required: chosenClaimType === ClaimTypes.QUALITY,
                pattern: METRIC_NUMBER_THREE_REG_EX,
              })}
              defaultValue="0"
              required
              error={errors?.claimValue}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Additional Paper Claim Value`}
            className="py-1 pt-5"
            style={{ width: '500px' }}
          >
            <Input
              name="additionalClaimValue"
              ref={register({
                pattern: METRIC_NUMBER_THREE_REG_EX,
              })}
              width="full"
              defaultValue="0"
              error={errors?.additionalClaimValue}
            />
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Total Paper Claim Value`}
            className="py-1 pt-5"
            style={{ width: '500px' }}
          >
            <Input
              name="totalClaimValue"
              ref={register()}
              width="full"
              readOnly
              value={getTotalClaimValueFromString(
                isEuUser,
                claimValue,
                additionalClaimValue
              )}
            />
          </InputLabel>
        </>
      )}
    </>
  );
};

export default FileClaimFormClaimValues;
