import { t } from '@lingui/macro';
import { produce } from 'immer';
import React from 'react';
import create from 'zustand';
import { DocumentTypes } from '../../../../types/DocumentTypes.interface';

interface DynamicColumn {
  id: string;
  display: string | React.ReactNode;
  property: string;
  show: boolean;
  checked: boolean;
}
export interface DynamicColumns {
  [DocumentTypes.WEIGHTLIST]: DynamicColumn;
  [DocumentTypes.DELIVERY]: DynamicColumn;
  [DocumentTypes.BAR_CODE_WEIGHT_LIST]: DynamicColumn;
  [DocumentTypes.ORDER_CONFIRMATION]?: DynamicColumn;
  [DocumentTypes.INVOICE]?: DynamicColumn;
  [DocumentTypes.CREDIT_NOTE]?: DynamicColumn;
  [DocumentTypes.CERTIFICATE_OF_ANALYSIS]?: DynamicColumn;
}
type DynamicColumnsState = {
  columns: Partial<DynamicColumns>;
  set: (fn: (...args: any[]) => any) => void;
};

const useDynamicColumnsStore = create<DynamicColumnsState>((set) => ({
  columns: {
    [DocumentTypes.WEIGHTLIST]: {
      id: 'wl',
      display: React.createElement(
        'div',
        null,
        t`Weight List #`,
        React.createElement('div', null, t`Bill of Lading #`)
      ),
      property: 'weightListDocId',
      show: true,
      checked: false,
    },
    [DocumentTypes.DELIVERY]: {
      id: 'de',
      display: t`Delivery`,
      property: 'deliveryDocId',
      show: false,
      checked: false,
    },
    [DocumentTypes.BAR_CODE_WEIGHT_LIST]: {
      id: 'bc',
      display: t`Bar code weight list`,
      property: 'barCodeWeightListDocId',
      show: false,
      checked: false,
    },
    [DocumentTypes.CERTIFICATE_OF_ANALYSIS]: {
      id: 'coa',
      display: t`Certificate of Analysis`,
      property: 'coaDocId',
      show: false,
      checked: false,
    },
  },
  set: (fn: (...args: any[]) => any) => set(produce(fn)),
}));

export default useDynamicColumnsStore;
